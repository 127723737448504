import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Selina Byeon | Software Developer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Selina Byeon Portfolio', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Selina Byeon',
  subtitle: 'I am a Software Engineer',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'public/static/4ccd13523eddd3694feac28b19d11786/selinapng.png',
  paragraphOne: '',
  paragraphTwo: '',
  paragraphThree: '',
  resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'pocketbook.png',
    title: 'Pocketbook',
    info:
      'Pocketbook is a mobile application connects book lovers and provides a live-chat platform to interact. Users can search for and conveniently organize books in their virtual bookshelves. They can also discuss their thoughts with others through virtual chat events.',
    info2:
      'Built with Redux, React Native, Node.js, Express, PostgreSQL, Sequelize, Passport, Google Books API, and Socket.IO',
    url: 'https://www.youtube.com/watch?v=AZBhTuaIdrk',
    repo: 'https://github.com/Capstone-Team-Artemis/pocketBook', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'game.jpg',
    title: 'Bubble Moon (Soon to be deployed)',
    info: 'Built with Phaser3 and Webpack',
    info2: 'A 2D shooting arcade game where players defeat enemies and collect stars',
    url: 'https://github.com/cobidev/react-simplefolio',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'plantparenthood.png',
    title: 'Plant Parenthood',
    info: 'Plant Parenthood is an e-commerce website for plant lovers to purchase greenery.',
    info2:
      'Designed database models and associations, and set up secured backend API routes using Express. Utilized local storage to enable cart function for guest users. Developed with React, Redux, Node, Express, express-session, local storage, Sequelize, PostgreSQL, Mocha, and Chai',
    url: 'https://grace-shopper-2021.herokuapp.com/allproducts',
    repo: 'https://github.com/gracehopper-team-storm/graceshopper-project', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'seonaebyeon1@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: nanoid(),
    //   name: 'twitter',
    //   url: '',
    // },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/ByeonSe',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/selina-byeon/',
    },
    {
      id: nanoid(),
      name: 'envelope',
      url: `mailto:seonaebyeon1@gmail.com`,
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
